import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { T } from "../../../hooks/useTranslate";
import { useMixPanel } from "../../../hooks/useMixPanel";
import { useApi } from "../../../hooks/useApi";
import { DistributorContext } from "../../../hooks/DistributorContext";

import { Stack, Typography, CircularProgress, useTheme } from "@mui/material";

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { LanguageContext } from "../../../hooks/LanguageContext";
import { MemberContext } from "../../../hooks/MemberContext";

const RankQualBox = ({ color1, color2, legNumber, name, leg, inDrawer, thirdLegCount }) => {
  const { localizeNumber } = useContext(LanguageContext);
  const { memberId, isDistributorLevelLimit } = useContext(MemberContext);
  const { setDistributorData } = useContext(DistributorContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();
  const { sendRequest } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const isClickAllowed = !(name === undefined || !leg?.value || isDistributorLevelLimit)

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.mode === 'light' ? color2 : color1
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? color1 : color2,
    },
  }));

  // linear progress bar doesn't handle big numbers over 100%
  const calculateProgress = () => {
    if (!leg || leg.value === undefined) {
      return 0
    }
    return leg.value === 0 && leg.requiredValue === 0 ? 0 :
      leg.value / leg.requiredValue * 100 > 100 ? 100 :
        leg.value / leg.requiredValue * 100;
  };

  const formatName = () => {
    if (JSON.stringify(name) === "{}") {
      return "-";
    } else if (name === undefined) {
      return "-";
    } else if (legNumber === "3") {
      return <>{localizeNumber(thirdLegCount.members)} <T>members</T> / {localizeNumber(thirdLegCount.partners)} <T>partners</T></>;
    } else {
      return name.humanName.fullName;
    }
  };

  const handleBoxClick = async () => {
    // if leg box is empty or already loading, do nothing
    if (!isClickAllowed || isLoading) return;

    // for leg box 1 and 2, navigate to profile based on type, for leg box 3, navigate to partners page
    if (["1", "2"].includes(legNumber)) {
      setIsLoading(true);
      try {
        // Get the customer data
        const response = await sendRequest({
          method: "get",
          addPrefix: false,
          endpoint: `${name.href}?expand=customer,profilePicture,sponsor`,
        });

        const userType = response.data.type === "Associate" ? "partner" : "member";

        // Set the distributor data to trigger the drawer's data fetch
        setDistributorData(response.data);

        logEvent("open_profile", {
          location: inDrawer ? "rank_tracker_sidebar" : "rank_tracker_dashboard",
          user_type: userType,
          leg_number: legNumber
        });

        navigate(`${location.pathname}?drawer=${userType}&id=${name.id.unicity}&drawerContent=profile`, {
          state: {
            customerData: response.data,
            location: location,
            userFirstName: response.data.humanName.firstName,
            userFullName: response.data.humanName.fullName
          }
        });
      } catch (error) {
        // Default to member if API call fails
        const userType = "member";
        logEvent("open_profile", {
          location: inDrawer ? "rank_tracker_sidebar" : "rank_tracker_dashboard",
          user_type: userType,
          leg_number: legNumber
        });
        navigate(`${location.pathname}?drawer=${userType}&id=${name.id.unicity}&drawerContent=profile`, {
          state: { location: location }
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      logEvent("partners_view", {
        location: inDrawer ? "rank_tracker_sidebar" : "rank_tracker_dashboard",
        user_type: "partner",
      })
      memberId ? navigate(`/partners/${memberId}`) : navigate(`/partners`)
    }
  }

  return (
    <Stack
      sx={{
        padding: "16px",
        background: !inDrawer && "rgba(145, 158, 171, 0.05)",
        borderRadius: "8px",
        cursor: (!isClickAllowed || isLoading) ? "not-allowed" : "pointer",
        "&:hover": {
          background: "rgba(145, 158, 171, 0.1)"
        },
        position: "relative"
      }}
      gap="10px"
      onClick={handleBoxClick}>
      {isLoading && (
        <Stack
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            borderRadius: "8px"
          }}
        >
          <CircularProgress size={24} />
        </Stack>
      )}
      <BorderLinearProgress variant="determinate" value={calculateProgress()} data-test-id={`leg-${legNumber}-progress-bar`} theme={theme} />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap="4px">
          <Stack
            data-test-id={`leg-${legNumber}-chip`}
            justifyContent="center"
            sx={{
              backgroundColor: color2,
              color: color1,
              fontSize: "9px",
              fontWeight: "600",
              padding: "2px 7px",
              borderRadius: "38px",
              whiteSpace: 'nowrap'
            }}>
            <T>leg</T> {legNumber}
          </Stack>
          <Typography
            data-test-id={`leg-${legNumber}-name`}
            variant="h9"
          >
            {formatName()}
          </Typography>
        </Stack>
        <Stack direction="row">
          <Typography
            data-test-id={`leg-${legNumber}-value`}
            variant="h9"
          >
            {leg?.value ? localizeNumber(leg.value) : 0}&nbsp;
          </Typography>
          <Typography
            data-test-id={`leg-${legNumber}-required-value`}
            variant="h9"
            sx={{
              opacity: ".6"
            }}
          >
            {`/ ${leg?.requiredValue ? localizeNumber(leg.requiredValue) : 0}`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { RankQualBox };
