/**
 * Utility for converting country codes between ISO 3166-1 alpha-2 and alpha-3 formats
 */

// Country code mappings (ISO 3166-1)
const countryCodesMap: Record<string, { alpha2: string; alpha3: string; name: string }> = {
  AFG: { alpha2: 'AF', alpha3: 'AFG', name: 'Afghanistan' },
  ALB: { alpha2: 'AL', alpha3: 'ALB', name: 'Albania' },
  DZA: { alpha2: 'DZ', alpha3: 'DZA', name: 'Algeria' },
  AND: { alpha2: 'AD', alpha3: 'AND', name: 'Andorra' },
  AGO: { alpha2: 'AO', alpha3: 'AGO', name: 'Angola' },
  ATG: { alpha2: 'AG', alpha3: 'ATG', name: 'Antigua and Barbuda' },
  ARG: { alpha2: 'AR', alpha3: 'ARG', name: 'Argentina' },
  ARM: { alpha2: 'AM', alpha3: 'ARM', name: 'Armenia' },
  AUS: { alpha2: 'AU', alpha3: 'AUS', name: 'Australia' },
  AUT: { alpha2: 'AT', alpha3: 'AUT', name: 'Austria' },
  AZE: { alpha2: 'AZ', alpha3: 'AZE', name: 'Azerbaijan' },
  BHS: { alpha2: 'BS', alpha3: 'BHS', name: 'Bahamas' },
  BHR: { alpha2: 'BH', alpha3: 'BHR', name: 'Bahrain' },
  BGD: { alpha2: 'BD', alpha3: 'BGD', name: 'Bangladesh' },
  BRB: { alpha2: 'BB', alpha3: 'BRB', name: 'Barbados' },
  BLR: { alpha2: 'BY', alpha3: 'BLR', name: 'Belarus' },
  BEL: { alpha2: 'BE', alpha3: 'BEL', name: 'Belgium' },
  BLZ: { alpha2: 'BZ', alpha3: 'BLZ', name: 'Belize' },
  BEN: { alpha2: 'BJ', alpha3: 'BEN', name: 'Benin' },
  BTN: { alpha2: 'BT', alpha3: 'BTN', name: 'Bhutan' },
  BOL: { alpha2: 'BO', alpha3: 'BOL', name: 'Bolivia' },
  BIH: { alpha2: 'BA', alpha3: 'BIH', name: 'Bosnia and Herzegovina' },
  BWA: { alpha2: 'BW', alpha3: 'BWA', name: 'Botswana' },
  BRA: { alpha2: 'BR', alpha3: 'BRA', name: 'Brazil' },
  BRN: { alpha2: 'BN', alpha3: 'BRN', name: 'Brunei Darussalam' },
  BGR: { alpha2: 'BG', alpha3: 'BGR', name: 'Bulgaria' },
  BFA: { alpha2: 'BF', alpha3: 'BFA', name: 'Burkina Faso' },
  BDI: { alpha2: 'BI', alpha3: 'BDI', name: 'Burundi' },
  KHM: { alpha2: 'KH', alpha3: 'KHM', name: 'Cambodia' },
  CMR: { alpha2: 'CM', alpha3: 'CMR', name: 'Cameroon' },
  CAN: { alpha2: 'CA', alpha3: 'CAN', name: 'Canada' },
  CPV: { alpha2: 'CV', alpha3: 'CPV', name: 'Cape Verde' },
  CAF: { alpha2: 'CF', alpha3: 'CAF', name: 'Central African Republic' },
  TCD: { alpha2: 'TD', alpha3: 'TCD', name: 'Chad' },
  CHL: { alpha2: 'CL', alpha3: 'CHL', name: 'Chile' },
  CHN: { alpha2: 'CN', alpha3: 'CHN', name: 'China' },
  COL: { alpha2: 'CO', alpha3: 'COL', name: 'Colombia' },
  COM: { alpha2: 'KM', alpha3: 'COM', name: 'Comoros' },
  COG: { alpha2: 'CG', alpha3: 'COG', name: 'Congo' },
  COD: { alpha2: 'CD', alpha3: 'COD', name: 'Congo, the Democratic Republic of the' },
  CRI: { alpha2: 'CR', alpha3: 'CRI', name: 'Costa Rica' },
  CIV: { alpha2: 'CI', alpha3: 'CIV', name: 'Côte d\'Ivoire' },
  HRV: { alpha2: 'HR', alpha3: 'HRV', name: 'Croatia' },
  CUB: { alpha2: 'CU', alpha3: 'CUB', name: 'Cuba' },
  CYP: { alpha2: 'CY', alpha3: 'CYP', name: 'Cyprus' },
  CZE: { alpha2: 'CZ', alpha3: 'CZE', name: 'Czech Republic' },
  DNK: { alpha2: 'DK', alpha3: 'DNK', name: 'Denmark' },
  DJI: { alpha2: 'DJ', alpha3: 'DJI', name: 'Djibouti' },
  DMA: { alpha2: 'DM', alpha3: 'DMA', name: 'Dominica' },
  DOM: { alpha2: 'DO', alpha3: 'DOM', name: 'Dominican Republic' },
  ECU: { alpha2: 'EC', alpha3: 'ECU', name: 'Ecuador' },
  EGY: { alpha2: 'EG', alpha3: 'EGY', name: 'Egypt' },
  SLV: { alpha2: 'SV', alpha3: 'SLV', name: 'El Salvador' },
  GNQ: { alpha2: 'GQ', alpha3: 'GNQ', name: 'Equatorial Guinea' },
  ERI: { alpha2: 'ER', alpha3: 'ERI', name: 'Eritrea' },
  EST: { alpha2: 'EE', alpha3: 'EST', name: 'Estonia' },
  ETH: { alpha2: 'ET', alpha3: 'ETH', name: 'Ethiopia' },
  FJI: { alpha2: 'FJ', alpha3: 'FJI', name: 'Fiji' },
  FIN: { alpha2: 'FI', alpha3: 'FIN', name: 'Finland' },
  FRA: { alpha2: 'FR', alpha3: 'FRA', name: 'France' },
  GAB: { alpha2: 'GA', alpha3: 'GAB', name: 'Gabon' },
  GMB: { alpha2: 'GM', alpha3: 'GMB', name: 'Gambia' },
  GEO: { alpha2: 'GE', alpha3: 'GEO', name: 'Georgia' },
  DEU: { alpha2: 'DE', alpha3: 'DEU', name: 'Germany' },
  GHA: { alpha2: 'GH', alpha3: 'GHA', name: 'Ghana' },
  GRC: { alpha2: 'GR', alpha3: 'GRC', name: 'Greece' },
  GRD: { alpha2: 'GD', alpha3: 'GRD', name: 'Grenada' },
  GTM: { alpha2: 'GT', alpha3: 'GTM', name: 'Guatemala' },
  GIN: { alpha2: 'GN', alpha3: 'GIN', name: 'Guinea' },
  GNB: { alpha2: 'GW', alpha3: 'GNB', name: 'Guinea-Bissau' },
  GUY: { alpha2: 'GY', alpha3: 'GUY', name: 'Guyana' },
  HTI: { alpha2: 'HT', alpha3: 'HTI', name: 'Haiti' },
  VAT: { alpha2: 'VA', alpha3: 'VAT', name: 'Holy See (Vatican City State)' },
  HND: { alpha2: 'HN', alpha3: 'HND', name: 'Honduras' },
  HUN: { alpha2: 'HU', alpha3: 'HUN', name: 'Hungary' },
  ISL: { alpha2: 'IS', alpha3: 'ISL', name: 'Iceland' },
  IND: { alpha2: 'IN', alpha3: 'IND', name: 'India' },
  IDN: { alpha2: 'ID', alpha3: 'IDN', name: 'Indonesia' },
  IRN: { alpha2: 'IR', alpha3: 'IRN', name: 'Iran, Islamic Republic of' },
  IRQ: { alpha2: 'IQ', alpha3: 'IRQ', name: 'Iraq' },
  IRL: { alpha2: 'IE', alpha3: 'IRL', name: 'Ireland' },
  ISR: { alpha2: 'IL', alpha3: 'ISR', name: 'Israel' },
  ITA: { alpha2: 'IT', alpha3: 'ITA', name: 'Italy' },
  JAM: { alpha2: 'JM', alpha3: 'JAM', name: 'Jamaica' },
  JPN: { alpha2: 'JP', alpha3: 'JPN', name: 'Japan' },
  JOR: { alpha2: 'JO', alpha3: 'JOR', name: 'Jordan' },
  KAZ: { alpha2: 'KZ', alpha3: 'KAZ', name: 'Kazakhstan' },
  KEN: { alpha2: 'KE', alpha3: 'KEN', name: 'Kenya' },
  KIR: { alpha2: 'KI', alpha3: 'KIR', name: 'Kiribati' },
  PRK: { alpha2: 'KP', alpha3: 'PRK', name: 'Korea, Democratic People\'s Republic of' },
  KOR: { alpha2: 'KR', alpha3: 'KOR', name: 'Korea, Republic of' },
  KWT: { alpha2: 'KW', alpha3: 'KWT', name: 'Kuwait' },
  KGZ: { alpha2: 'KG', alpha3: 'KGZ', name: 'Kyrgyzstan' },
  LAO: { alpha2: 'LA', alpha3: 'LAO', name: 'Lao People\'s Democratic Republic' },
  LVA: { alpha2: 'LV', alpha3: 'LVA', name: 'Latvia' },
  LBN: { alpha2: 'LB', alpha3: 'LBN', name: 'Lebanon' },
  LSO: { alpha2: 'LS', alpha3: 'LSO', name: 'Lesotho' },
  LBR: { alpha2: 'LR', alpha3: 'LBR', name: 'Liberia' },
  LBY: { alpha2: 'LY', alpha3: 'LBY', name: 'Libya' },
  LIE: { alpha2: 'LI', alpha3: 'LIE', name: 'Liechtenstein' },
  LTU: { alpha2: 'LT', alpha3: 'LTU', name: 'Lithuania' },
  LUX: { alpha2: 'LU', alpha3: 'LUX', name: 'Luxembourg' },
  MKD: { alpha2: 'MK', alpha3: 'MKD', name: 'Macedonia, the Former Yugoslav Republic of' },
  MDG: { alpha2: 'MG', alpha3: 'MDG', name: 'Madagascar' },
  MWI: { alpha2: 'MW', alpha3: 'MWI', name: 'Malawi' },
  MYS: { alpha2: 'MY', alpha3: 'MYS', name: 'Malaysia' },
  MDV: { alpha2: 'MV', alpha3: 'MDV', name: 'Maldives' },
  MLI: { alpha2: 'ML', alpha3: 'MLI', name: 'Mali' },
  MLT: { alpha2: 'MT', alpha3: 'MLT', name: 'Malta' },
  MHL: { alpha2: 'MH', alpha3: 'MHL', name: 'Marshall Islands' },
  MRT: { alpha2: 'MR', alpha3: 'MRT', name: 'Mauritania' },
  MUS: { alpha2: 'MU', alpha3: 'MUS', name: 'Mauritius' },
  MEX: { alpha2: 'MX', alpha3: 'MEX', name: 'Mexico' },
  FSM: { alpha2: 'FM', alpha3: 'FSM', name: 'Micronesia, Federated States of' },
  MDA: { alpha2: 'MD', alpha3: 'MDA', name: 'Moldova, Republic of' },
  MCO: { alpha2: 'MC', alpha3: 'MCO', name: 'Monaco' },
  MNG: { alpha2: 'MN', alpha3: 'MNG', name: 'Mongolia' },
  MNE: { alpha2: 'ME', alpha3: 'MNE', name: 'Montenegro' },
  MAR: { alpha2: 'MA', alpha3: 'MAR', name: 'Morocco' },
  MOZ: { alpha2: 'MZ', alpha3: 'MOZ', name: 'Mozambique' },
  MMR: { alpha2: 'MM', alpha3: 'MMR', name: 'Myanmar' },
  NAM: { alpha2: 'NA', alpha3: 'NAM', name: 'Namibia' },
  NRU: { alpha2: 'NR', alpha3: 'NRU', name: 'Nauru' },
  NPL: { alpha2: 'NP', alpha3: 'NPL', name: 'Nepal' },
  NLD: { alpha2: 'NL', alpha3: 'NLD', name: 'Netherlands' },
  NZL: { alpha2: 'NZ', alpha3: 'NZL', name: 'New Zealand' },
  NIC: { alpha2: 'NI', alpha3: 'NIC', name: 'Nicaragua' },
  NER: { alpha2: 'NE', alpha3: 'NER', name: 'Niger' },
  NGA: { alpha2: 'NG', alpha3: 'NGA', name: 'Nigeria' },
  NOR: { alpha2: 'NO', alpha3: 'NOR', name: 'Norway' },
  OMN: { alpha2: 'OM', alpha3: 'OMN', name: 'Oman' },
  PAK: { alpha2: 'PK', alpha3: 'PAK', name: 'Pakistan' },
  PLW: { alpha2: 'PW', alpha3: 'PLW', name: 'Palau' },
  PSE: { alpha2: 'PS', alpha3: 'PSE', name: 'Palestine, State of' },
  PAN: { alpha2: 'PA', alpha3: 'PAN', name: 'Panama' },
  PNG: { alpha2: 'PG', alpha3: 'PNG', name: 'Papua New Guinea' },
  PRY: { alpha2: 'PY', alpha3: 'PRY', name: 'Paraguay' },
  PER: { alpha2: 'PE', alpha3: 'PER', name: 'Peru' },
  PHL: { alpha2: 'PH', alpha3: 'PHL', name: 'Philippines' },
  POL: { alpha2: 'PL', alpha3: 'POL', name: 'Poland' },
  PRT: { alpha2: 'PT', alpha3: 'PRT', name: 'Portugal' },
  QAT: { alpha2: 'QA', alpha3: 'QAT', name: 'Qatar' },
  ROU: { alpha2: 'RO', alpha3: 'ROU', name: 'Romania' },
  RUS: { alpha2: 'RU', alpha3: 'RUS', name: 'Russian Federation' },
  RWA: { alpha2: 'RW', alpha3: 'RWA', name: 'Rwanda' },
  KNA: { alpha2: 'KN', alpha3: 'KNA', name: 'Saint Kitts and Nevis' },
  LCA: { alpha2: 'LC', alpha3: 'LCA', name: 'Saint Lucia' },
  VCT: { alpha2: 'VC', alpha3: 'VCT', name: 'Saint Vincent and the Grenadines' },
  WSM: { alpha2: 'WS', alpha3: 'WSM', name: 'Samoa' },
  SMR: { alpha2: 'SM', alpha3: 'SMR', name: 'San Marino' },
  STP: { alpha2: 'ST', alpha3: 'STP', name: 'Sao Tome and Principe' },
  SAU: { alpha2: 'SA', alpha3: 'SAU', name: 'Saudi Arabia' },
  SEN: { alpha2: 'SN', alpha3: 'SEN', name: 'Senegal' },
  SRB: { alpha2: 'RS', alpha3: 'SRB', name: 'Serbia' },
  SYC: { alpha2: 'SC', alpha3: 'SYC', name: 'Seychelles' },
  SLE: { alpha2: 'SL', alpha3: 'SLE', name: 'Sierra Leone' },
  SGP: { alpha2: 'SG', alpha3: 'SGP', name: 'Singapore' },
  SVK: { alpha2: 'SK', alpha3: 'SVK', name: 'Slovakia' },
  SVN: { alpha2: 'SI', alpha3: 'SVN', name: 'Slovenia' },
  SLB: { alpha2: 'SB', alpha3: 'SLB', name: 'Solomon Islands' },
  SOM: { alpha2: 'SO', alpha3: 'SOM', name: 'Somalia' },
  ZAF: { alpha2: 'ZA', alpha3: 'ZAF', name: 'South Africa' },
  SSD: { alpha2: 'SS', alpha3: 'SSD', name: 'South Sudan' },
  ESP: { alpha2: 'ES', alpha3: 'ESP', name: 'Spain' },
  LKA: { alpha2: 'LK', alpha3: 'LKA', name: 'Sri Lanka' },
  SDN: { alpha2: 'SD', alpha3: 'SDN', name: 'Sudan' },
  SUR: { alpha2: 'SR', alpha3: 'SUR', name: 'Suriname' },
  SWZ: { alpha2: 'SZ', alpha3: 'SWZ', name: 'Eswatini' },
  SWE: { alpha2: 'SE', alpha3: 'SWE', name: 'Sweden' },
  CHE: { alpha2: 'CH', alpha3: 'CHE', name: 'Switzerland' },
  SYR: { alpha2: 'SY', alpha3: 'SYR', name: 'Syrian Arab Republic' },
  TWN: { alpha2: 'TW', alpha3: 'TWN', name: 'Taiwan' },
  TJK: { alpha2: 'TJ', alpha3: 'TJK', name: 'Tajikistan' },
  TZA: { alpha2: 'TZ', alpha3: 'TZA', name: 'Tanzania, United Republic of' },
  THA: { alpha2: 'TH', alpha3: 'THA', name: 'Thailand' },
  TLS: { alpha2: 'TL', alpha3: 'TLS', name: 'Timor-Leste' },
  TGO: { alpha2: 'TG', alpha3: 'TGO', name: 'Togo' },
  TON: { alpha2: 'TO', alpha3: 'TON', name: 'Tonga' },
  TTO: { alpha2: 'TT', alpha3: 'TTO', name: 'Trinidad and Tobago' },
  TUN: { alpha2: 'TN', alpha3: 'TUN', name: 'Tunisia' },
  TUR: { alpha2: 'TR', alpha3: 'TUR', name: 'Turkey' },
  TKM: { alpha2: 'TM', alpha3: 'TKM', name: 'Turkmenistan' },
  TUV: { alpha2: 'TV', alpha3: 'TUV', name: 'Tuvalu' },
  UGA: { alpha2: 'UG', alpha3: 'UGA', name: 'Uganda' },
  UKR: { alpha2: 'UA', alpha3: 'UKR', name: 'Ukraine' },
  ARE: { alpha2: 'AE', alpha3: 'ARE', name: 'United Arab Emirates' },
  GBR: { alpha2: 'GB', alpha3: 'GBR', name: 'United Kingdom' },
  USA: { alpha2: 'US', alpha3: 'USA', name: 'United States' },
  URY: { alpha2: 'UY', alpha3: 'URY', name: 'Uruguay' },
  UZB: { alpha2: 'UZ', alpha3: 'UZB', name: 'Uzbekistan' },
  VUT: { alpha2: 'VU', alpha3: 'VUT', name: 'Vanuatu' },
  VEN: { alpha2: 'VE', alpha3: 'VEN', name: 'Venezuela, Bolivarian Republic of' },
  VNM: { alpha2: 'VN', alpha3: 'VNM', name: 'Viet Nam' },
  YEM: { alpha2: 'YE', alpha3: 'YEM', name: 'Yemen' },
  ZMB: { alpha2: 'ZM', alpha3: 'ZMB', name: 'Zambia' },
  ZWE: { alpha2: 'ZW', alpha3: 'ZWE', name: 'Zimbabwe' },
};

// Lookup indexes for faster conversion
const alpha2ToAlpha3Map: Record<string, string> = {};
const alpha3ToAlpha2Map: Record<string, string> = {};

// Initialize lookup maps
Object.entries(countryCodesMap).forEach(([alpha3, data]) => {
  const { alpha2 } = data;
  alpha2ToAlpha3Map[alpha2] = alpha3;
  alpha3ToAlpha2Map[alpha3] = alpha2;
});

/**
 * Converts a country code from alpha-2 format to alpha-3 format
 * @param alpha2 - The ISO 3166-1 alpha-2 country code (2 letters)
 * @returns The corresponding alpha-3 country code or undefined if not found
 */
export const alpha2ToAlpha3 = (alpha2: string): string | undefined => {
  // Convert to uppercase for case-insensitive comparison
  const code = alpha2.toUpperCase();
  return alpha2ToAlpha3Map[code];
};

/**
 * Converts a country code from alpha-3 format to alpha-2 format
 * @param alpha3 - The ISO 3166-1 alpha-3 country code (3 letters)
 * @returns The corresponding alpha-2 country code or undefined if not found
 */
export const alpha3ToAlpha2 = (alpha3: string): string | undefined => {
  // Convert to uppercase for case-insensitive comparison
  const code = alpha3.toUpperCase();
  return alpha3ToAlpha2Map[code];
};

/**
 * Gets country information by alpha-2 or alpha-3 code
 * @param code - The ISO 3166-1 alpha-2 or alpha-3 country code
 * @returns The country information or undefined if not found
 */
export const getCountryInfo = (code: string): {
  alpha2: string;
  alpha3: string;
  name: string
} | undefined => {
  const upperCode = code.toUpperCase();

  // Check if it's an alpha-3 code
  if (countryCodesMap[upperCode]) {
    return countryCodesMap[upperCode];
  }

  // Check if it's an alpha-2 code
  const alpha3 = alpha2ToAlpha3Map[upperCode];
  return alpha3 ? countryCodesMap[alpha3] : undefined;
};

/**
 * Gets all country codes and names
 * @returns Array of all countries with their codes and names
 */
export const getAllCountries = (): Array<{
  alpha2: string;
  alpha3: string;
  name: string
}> => {
  return Object.values(countryCodesMap);
};

const countryCodeConverter = {
  alpha2ToAlpha3,
  alpha3ToAlpha2,
  getCountryInfo,
  getAllCountries
};

export default countryCodeConverter; 