import { lazy } from "react";
import {
  RouterProvider,
  Outlet,
  createHashRouter,
} from "react-router-dom";

import { RequireAuth } from "../components/RequireAuth.jsx";
import { pagesWithSuspense, withDisplayName, withSuspense } from "../components/LazyComponents";

// Lazy loaded screens
const DashboardScreen = lazy(() =>
  import('../screens/DashboardScreen/DashboardScreen').then(module => ({
    default: module.DashboardScreen
  }))
);

const MyMembersScreen = lazy(() =>
  import('../screens/MyMembersScreen/MyMembersScreen.jsx').then(module => ({
    default: module.MyMembersScreen
  }))
);

const PartnersScreen = lazy(() =>
  import('../screens/PartnersScreen/PartnersScreen.jsx').then(module => ({
    default: module.PartnersScreen
  }))
);

const ErrorScreen = lazy(() =>
  import('../screens/ErrorScreen/ErrorScreen.jsx').then(module => ({
    default: module.ErrorScreen
  }))
);

const LoginScreen = lazy(() =>
  import('../screens/LoginScreen/LoginScreen.jsx').then(module => ({
    default: module.LoginScreen
  }))
);

const ActionHubScreen = lazy(() =>
  import('../screens/ActionHubScreen/ActionHubScreen.jsx').then(module => ({
    default: module.ActionHubScreen
  }))
);

const CenturionScreen = lazy(() =>
  import('../screens/CenturionScreen/CenturionScreen.jsx').then(module => ({
    default: module.CenturionScreen
  }))
);

const ReferralLinksScreen = lazy(() =>
  import('../screens/ReferralLinksScreen/ReferralLinksScreen.jsx').then(module => ({
    default: module.ReferralLinksScreen
  }))
);

const EarningsScreen = lazy(() =>
  import('../screens/EarningsScreen/EarningsScreen.jsx').then(module => ({
    default: module.EarningsScreen
  }))
);

const OrderHistoryScreen = lazy(() =>
  import('../screens/OrderHistory/OrderHistoryScreen.tsx').then(module => ({
    default: module.OrderHistoryScreen
  }))
);

const OrderDetailsScreen = lazy(() =>
  import('../screens/OrderHistory/OrderDetailsScreen.tsx').then(module => ({
    default: module.OrderDetailsScreen
  }))
);

const SettingsScreen = lazy(() =>
  import('../screens/SettingsScreen/SettingsScreen.jsx').then(module => ({
    default: module.SettingsScreen
  }))
);

const SettingsMyProfileSection = lazy(() =>
  import('../screens/SettingsScreen/MyProfile/SettingsMyProfileSection.jsx').then(module => ({
    default: module.SettingsMyProfileSection
  }))
);

const SettingsPersonalInfoSection = lazy(() =>
  import('../screens/SettingsScreen/PersonalInfo/SettingsPersonalInfoSection.jsx').then(module => ({
    default: module.SettingsPersonalInfoSection
  }))
);

const SettingsSponsorEnrollerSection = lazy(() =>
  import('../screens/SettingsScreen/SponsorEnroller/SettingsSponsorEnrollerSection.jsx').then(module => ({
    default: module.SettingsSponsorEnrollerSection
  }))
);

const SettingsPlacementProgramSection = lazy(() =>
  import('../screens/SettingsScreen/PlacementProgram/SettingsPlacementProgramSection.jsx').then(module => ({
    default: module.SettingsPlacementProgramSection
  }))
);

const ElectronicIDSection = lazy(() =>
  import('../screens/SettingsScreen/ElectronicID/ElectronicIDSection.jsx').then(module => ({
    default: module.ElectronicIDSection
  }))
);

const PartnersSearchResultsScreen = lazy(() =>
  import('../screens/PartnersSearchResultsScreen/PartnersSearchResultsScreen.jsx').then(module => ({
    default: module.PartnersSearchResultsScreen
  }))
);

const AllStatementsScreen = lazy(() =>
  import('../screens/EarningsScreen/AllStatementsScreen.jsx').then(module => ({
    default: module.AllStatementsScreen
  }))
);

const NewFeelGreatMembersScreen = lazy(() =>
  import('../screens/ActionHubScreen/NewFeelGreatMembersScreen.jsx').then(module => ({
    default: module.NewFeelGreatMembersScreen
  }))
);

const OrderRemindersScreen = lazy(() =>
  import('../screens/ActionHubScreen/OrderRemindersScreen.jsx').then(module => ({
    default: module.OrderRemindersScreen
  }))
);

const PartialsScreen = lazy(() =>
  import('../screens/ActionHubScreen/PartialsScreen.jsx').then(module => ({
    default: module.PartialsScreen
  }))
);

const AllNewsScreen = lazy(() =>
  import('../screens/AllNewsScreen/AllNewsScreen.jsx').then(module => ({
    default: module.AllNewsScreen
  }))
);

const LibraryScreen = lazy(() =>
  import('../screens/Library/LibraryScreen.tsx').then(module => ({
    default: module.LibraryScreen
  }))
);

const ThemeSection = lazy(() =>
  import('../screens/SettingsScreen/Theme/ThemeSection.tsx').then(module => ({
    default: module.ThemeSection
  }))
);

// Wrap the components with Suspense using this special HOC
const LazyDashboardScreen = withDisplayName("DashboardScreen", pagesWithSuspense(DashboardScreen));
const LazyMyMembersScreen = withDisplayName("MyMembersScreen", pagesWithSuspense(MyMembersScreen));
const LazyPartnersScreen = withDisplayName("PartnersScreen", pagesWithSuspense(PartnersScreen));
const LazyErrorScreen = withDisplayName("ErrorScreen", pagesWithSuspense(ErrorScreen));
const LazyLoginScreen = withDisplayName("LoginScreen", withSuspense(LoginScreen));
const LazyActionHubSreen = withDisplayName("ActionHubScreen", pagesWithSuspense(ActionHubScreen));
const LazyCenturionScreen = withDisplayName("CenturionScreen", pagesWithSuspense(CenturionScreen));
const LazyReferralLinksScreen = withDisplayName("ReferralLinksScreen", pagesWithSuspense(ReferralLinksScreen));
const LazyEarningsScreen = withDisplayName("EarningsScreen", pagesWithSuspense(EarningsScreen));
const LazyOrderHistoryScreen = withDisplayName("OrderHistoryScreen", pagesWithSuspense(OrderHistoryScreen));
const LazyOrderDetailsScreen = withDisplayName("OrderDetailsScreen", pagesWithSuspense(OrderDetailsScreen));
const LazySettingsScreen = withDisplayName("SettingsScreen", pagesWithSuspense(SettingsScreen));
const LazySettingsMyProfileSection = withDisplayName("SettingsMyProfileSection", withSuspense(SettingsMyProfileSection));
const LazySettingsPersonalInfoSection = withDisplayName("SettingsPersonalInfoSection", withSuspense(SettingsPersonalInfoSection));
const LazySettingsSponsorEnrollerSection = withDisplayName("SettingsSponsorEnrollerSection", withSuspense(SettingsSponsorEnrollerSection));
const LazySettingsPlacementProgramSection = withDisplayName("SettingsPlacementProgramSection", withSuspense(SettingsPlacementProgramSection));
const LazyElectronicIDSection = withDisplayName("ElectronicIDSection", withSuspense(ElectronicIDSection));
const LazyPartnersSearchResultsScreen = withDisplayName("PartnersSearchResultsScreen", pagesWithSuspense(PartnersSearchResultsScreen));
const LazyAllStatementsScreen = withDisplayName("AllStatementsScreen", pagesWithSuspense(AllStatementsScreen));
const LazyNewFeelGreatMembersScreen = withDisplayName("NewFeelGreatMembersScreen", pagesWithSuspense(NewFeelGreatMembersScreen));
const LazyOrderRemindersScreen = withDisplayName("OrderRemindersScreen", pagesWithSuspense(OrderRemindersScreen));
const LazyPartialsScreen = withDisplayName("PartialsScreen", pagesWithSuspense(PartialsScreen));
const LazyAllNewsScreen = withDisplayName("AllNewsScreen", pagesWithSuspense(AllNewsScreen));
const LazyLibraryScreen = withDisplayName("LibraryScreen", pagesWithSuspense(LibraryScreen));
const LazyThemeSectionScreen = withDisplayName("ThemeSection", withSuspense(ThemeSection));

//given the name of a feature flag,
// get its value from the query string,
// and assume it is boolean with default false
const getFeatureFlagFromQueryString = (flagName) =>
  window?.location?.hash
    ?.split("?")[1]
    ?.split("&")
    ?.find((p) => p.indexOf(flagName) !== -1)
    ?.split("=")
    ?.slice(-1)[0] || false;

//check for feature flags when the application first loads
//this value will only be set once, since routes are only built once - so this should persist in webviews
const isWebView = getFeatureFlagFromQueryString("isWebView");
const isPwa = getFeatureFlagFromQueryString("isPwa");


const OfficeRouter = () => {
  const routes = [
    {
      path: "/",
      element: <RequireAuth>
        <LazyDashboardScreen pageTitle="dashboard"/>
      </RequireAuth>,
    },
    {
      path: "/login",
      element: <LazyLoginScreen isWebView={isWebView} isPwa={isPwa} />,
      children: [
        {
          path: "/login/sl/:token",
          element: <LoginScreen isWebView={isWebView} isPwa={isPwa} />,
        },
      ],
    },
    {
      path: "/dashboard",
      element: <RequireAuth>
        <LazyDashboardScreen pageTitle="dashboard" />
      </RequireAuth>,
    },
    {
      path: "/members",
      element: <RequireAuth>
        <LazyMyMembersScreen pageTitle="members" isWebView={isWebView} />
      </RequireAuth>,
    },
    {
      path: "/partners",
      element: <RequireAuth>
        <LazyPartnersScreen isWebView={isWebView} pageTitle={"partners"} />
      </RequireAuth>,
      children: [
        {
          path: "/partners/:distributorId",
          element: <LazyPartnersScreen isWebView={isWebView} pageTitle={"partners"} />,
        }
      ],
      errorElement: <LazyErrorScreen isWebView={isWebView} pageTitle={"error"}/>,
    },
    {
      path: "/partners/search-results",
      element: <RequireAuth>
        <LazyPartnersSearchResultsScreen 
          isWebView={isWebView}
          showBackArrow={true}
          pageTitle="search_results"
          backTo="/partners"
        />
      </RequireAuth>,
    },
    {
      path: "/actionhub",
      element: <RequireAuth>
        <LazyActionHubSreen pageTitle="action_hub" />
      </RequireAuth>
    },
    {
      path: "/actionhub/newfeelgreat",
      element: <RequireAuth>
        <LazyNewFeelGreatMembersScreen
          isWebView={isWebView}
          showBackArrow={true}
          pageTitle="new_feel_great_members"
          backTo="/actionhub"
        />
      </RequireAuth>
    },
    {
      path: "/actionhub/reminders",
      element: <RequireAuth>
        <LazyOrderRemindersScreen 
          showBackArrow={true}
          pageTitle="order_reminders" 
          backTo="/actionhub"
        />
      </RequireAuth>
    },
    {
      path: "/actionhub/partials",
      element: <RequireAuth>
        <LazyPartialsScreen
          showBackArrow={true}
          pageTitle="partials"
          backTo="/actionhub"
        />
      </RequireAuth>
    },
    {
      path: "/centurion",
      element: <RequireAuth>
        <LazyCenturionScreen pageTitle="centurion" />
      </RequireAuth>,
    },
    {
      path: "/referrals",
      element: <RequireAuth>
        <LazyReferralLinksScreen pageTitle="referral_links" />
      </RequireAuth>,
    },
    {
      path: "/earnings/statements",
      element: <RequireAuth>
        <LazyAllStatementsScreen
          isWebView={isWebView} 
          pageTitle="statements"
          showBackArrow={true}
          backTo="/earnings"
        />
      </RequireAuth>,
    },
    {
      path: "/orders",
      element: <RequireAuth>
        <LazyOrderHistoryScreen pageTitle="order_history" />
      </RequireAuth>,
    },
    {
      path: "/orders/:orderHref",
      element: <RequireAuth>
        <LazyOrderDetailsScreen pageTitle="order_details" />
      </RequireAuth>,
    },
    {
      path: "/earnings",
      element: <RequireAuth>
        <LazyEarningsScreen isWebView={isWebView} pageTitle="earnings" />
      </RequireAuth>,
    },
    {
      path: "/settings",
      element: <RequireAuth>
        <LazySettingsScreen pageTitle="settings">
          <Outlet />
        </LazySettingsScreen>
      </RequireAuth>,
      children: [
        {
          path: 'my-profile',
          element: <LazySettingsMyProfileSection />,
        },
        {
          path: 'personal-information',
          element: <LazySettingsPersonalInfoSection />,
        },
        {
          path: 'sponsor-and-enroller',
          element: <LazySettingsSponsorEnrollerSection />,
        },
        {
          path: 'placement-program',
          element: <LazySettingsPlacementProgramSection />,
        },
        {
          path: 'electronic-id',
          element: <LazyElectronicIDSection />,
        },
        {
          path: 'theme',
          element: <LazyThemeSectionScreen />,
        },
      ]
    },
    {
      path: "/news",
      element: (
        <RequireAuth>
          <LazyAllNewsScreen 
            showBackArrow={true}
            pageTitle="unicity_news"
            backTo="/dashboard"
          />
        </RequireAuth>
      )
    },
    {
      path: "/library/*",
      element: <RequireAuth>
        <LazyLibraryScreen
          pageTitle="library"
          showBackArrow={false}
          onBackArrowClick={() => { }}
          isWebView={false}
        />
      </RequireAuth>,
    },
    {
      path: "*",
      element: <RequireAuth>
        <LazyDashboardScreen pageTitle="dashboard" />
      </RequireAuth>
    }
  ];

  const router = createHashRouter(routes);

  return <RouterProvider router={router} />;
};

export { OfficeRouter };
