import React from "react";
import { Stack, useTheme } from "@mui/material";

import { RankQualBox } from "./RankQualBox";
import { THEME_COLORS } from "../../../themes/themeColors";

const RankQualProgBarContainer = ({
  selectedRankDetails, name1, name2, name3, inDrawer, thirdLegCount
}) => {
  const theme = useTheme();

  const leg1 = selectedRankDetails?.metrics?.filter(item => item.name.includes("ov_leg1"))[0];
  const leg2 = selectedRankDetails?.metrics?.filter(item => item.name.includes("ov_leg2"))[0];
  const leg3 = selectedRankDetails?.metrics?.filter(item => item.name.includes("ov_leg3"))[0];

  return (
    <Stack
      gap="8px"
      sx={{
        padding: inDrawer ?
          "0px 0px 24px 0px" : "24px 20px 24px 20px"
      }}
    >
      {
        <RankQualBox
          color1={theme.palette.mode === 'light' ? THEME_COLORS.LIGHT.LEGS.LEG1.COLOR1 : THEME_COLORS.DARK.LEGS.LEG1.COLOR1}
          color2={theme.palette.mode === 'light' ? THEME_COLORS.LIGHT.LEGS.LEG1.COLOR2 : THEME_COLORS.DARK.LEGS.LEG1.COLOR2}
          legNumber="1"
          name={name1}
          leg={leg1}
          inDrawer={inDrawer} />
      }
      {
        <RankQualBox
          color1={theme.palette.mode === 'light' ? THEME_COLORS.LIGHT.LEGS.LEG2.COLOR1 : THEME_COLORS.DARK.LEGS.LEG2.COLOR1}
          color2={theme.palette.mode === 'light' ? THEME_COLORS.LIGHT.LEGS.LEG2.COLOR2 : THEME_COLORS.DARK.LEGS.LEG2.COLOR2}
          legNumber="2"
          name={name2}
          leg={leg2}
          inDrawer={inDrawer} />
      }
      {
        <RankQualBox
          color1={theme.palette.mode === 'light' ? THEME_COLORS.LIGHT.LEGS.LEG3.COLOR1 : THEME_COLORS.DARK.LEGS.LEG3.COLOR1}
          color2={theme.palette.mode === 'light' ? THEME_COLORS.LIGHT.LEGS.LEG3.COLOR2 : THEME_COLORS.DARK.LEGS.LEG3.COLOR2}
          legNumber="3"
          name={name3}
          leg={leg3}
          inDrawer={inDrawer}
          thirdLegCount={thirdLegCount}
        />
      }
    </Stack>
  )
}

export { RankQualProgBarContainer }