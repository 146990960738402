import { DocumentCategory, Country, Language, Region } from '../types/library.types';
import { ReactComponent as GettingStartedIcon } from '../../../assets/icons/Group.svg';
import { ReactComponent as ProductResourcesIcon } from '../../../assets/icons/product_resources_icon.svg';
import { ReactComponent as FeelGreatPackIcon } from '../../../assets/icons/feel_great_pack_icon.svg';
import { ReactComponent as DocumentsIcon } from '../../../assets/icons/lib-documents.svg';
import { ReactComponent as EventsIcon } from '../../../assets/icons/lib-events.svg';
import { ReactComponent as VideosIcon } from '../../../assets/icons/lib-videos.svg';

export const LIBRARY_CONFIG = {
  BASE_PATH: 'library_2.0',
  METADATA_FILE: 'metadata.json',
} as const;

export const LIBRARY_ROUTES = {
  ROOT: '/',
  CATEGORY: '/category/:categoryId',
  DOCUMENT: '/document/:documentId',
} as const;

export const DOCUMENT_CATEGORIES = [
  {
    id: DocumentCategory.DOCUMENTS,
    label: 'Documents',
    icon: 'document',
  },
  {
    id: DocumentCategory.EVENTS,
    label: 'Events',
    icon: 'event',
  },
  {
    id: DocumentCategory.FEEL_GREAT,
    label: 'Feel Great',
    icon: 'feel-great',
  },
  {
    id: DocumentCategory.PRODUCTS,
    label: 'Products',
    icon: 'product',
  },
  {
    id: DocumentCategory.UNICITY_BRANDING,
    label: 'Unicity Branding',
    icon: 'branding',
  },
  {
    id: DocumentCategory.VIDEOS,
    label: 'Videos',
    icon: 'video',
  },
] as const;

export const LIBRARY_MESSAGES = {
  WELCOME: 'Welcome to the Library, a resource center for everything that will support your business.',
  NO_RESULTS: 'Sorry, we couldn\'t find any results.',
  NO_DOCUMENTS: 'No documents available.',
  DOWNLOAD_ERROR: 'Unable to download the document. Please try again later.',
  VIEW_ERROR: 'Unable to view the document. Please try again later.',
} as const;

export const SEARCH_MIN_CHARS = 2;
export const LOADING_THRESHOLD_MS = 2000;

export const REGIONS: Region[] = [
  'Americas',
  'Europe',
  'Asia Pacific',
  'Middle East & Africa'
];

// Languages
export const ENGLISH: Language = {
  code: 'en',
  name: 'English',
  nameEnglish: 'English'
};

export const SPANISH: Language = {
  code: 'es',
  name: 'Español',
  nameEnglish: 'Spanish'
};

export const FRENCH: Language = {
  code: 'fr',
  name: 'Français',
  nameEnglish: 'French'
};

export const GERMAN: Language = {
  code: 'de',
  name: 'Deutsch',
  nameEnglish: 'German'
};

export const ITALIAN: Language = {
  code: 'it',
  name: 'Italiano',
  nameEnglish: 'Italian'
};

export const DUTCH: Language = {
  code: 'nl',
  name: 'Nederlands',
  nameEnglish: 'Dutch'
};

export const POLISH: Language = {
  code: 'pl',
  name: 'Polski',
  nameEnglish: 'Polish'
};

export const TURKISH: Language = {
  code: 'tr',
  name: 'Türkçe',
  nameEnglish: 'Turkish'
};

export const JAPANESE: Language = {
  code: 'ja',
  name: '日本語',
  nameEnglish: 'Japanese'
};

export const CHINESE: Language = {
  code: 'zh',
  name: '中文',
  nameEnglish: 'Chinese'
};

export const KHMER: Language = {
  code: 'km',
  name: 'ខ្មែរ',
  nameEnglish: 'Khmer'
};

export const INDONESIAN: Language = {
  code: 'id',
  name: 'Indonesia',
  nameEnglish: 'Indonesian'
};

export const THAI: Language = {
  code: 'th',
  name: 'ไทย',
  nameEnglish: 'Thai'
};

export const VIETNAMESE: Language = {
  code: 'vi',
  name: 'Tiếng Việt',
  nameEnglish: 'Vietnamese'
};

export const KOREAN: Language = {
  code: 'ko',
  name: '한국어',
  nameEnglish: 'Korean'
};

export const ARABIC: Language = {
  code: 'ar',
  name: 'العربية',
  nameEnglish: 'Arabic'
};

// Countries by region
export const AMERICAS_COUNTRIES: Country[] = [
  {
    code: 'US',
    name: 'United States',
    region: 'Americas',
    languages: [ENGLISH, SPANISH]
  },
  {
    code: 'CA',
    name: 'Canada',
    region: 'Americas',
    languages: [ENGLISH, FRENCH]
  },
  {
    code: 'CO',
    name: 'Colombia',
    region: 'Americas',
    languages: [SPANISH]
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    region: 'Americas',
    languages: [SPANISH]
  },
  {
    code: 'MX',
    name: 'Mexico',
    region: 'Americas',
    languages: [SPANISH]
  }
];

export const EUROPE_COUNTRIES: Country[] = [
  {
    code: 'AT',
    name: 'Austria',
    region: 'Europe',
    languages: [GERMAN]
  },
  {
    code: 'BE',
    name: 'Belgium',
    region: 'Europe',
    languages: [DUTCH, FRENCH]
  },
  {
    code: 'FI',
    name: 'Finland',
    region: 'Europe',
    languages: [ENGLISH]
  },
  {
    code: 'FR',
    name: 'France',
    region: 'Europe',
    languages: [FRENCH]
  },
  {
    code: 'DE',
    name: 'Germany',
    region: 'Europe',
    languages: [GERMAN]
  },
  {
    code: 'IT',
    name: 'Italy',
    region: 'Europe',
    languages: [ITALIAN]
  },
  {
    code: 'NL',
    name: 'Netherlands',
    region: 'Europe',
    languages: [DUTCH]
  },
  {
    code: 'NO',
    name: 'Norway',
    region: 'Europe',
    languages: [ENGLISH]
  },
  {
    code: 'PL',
    name: 'Poland',
    region: 'Europe',
    languages: [POLISH]
  },
  {
    code: 'ES',
    name: 'Spain',
    region: 'Europe',
    languages: [SPANISH]
  },
  {
    code: 'SE',
    name: 'Sweden',
    region: 'Europe',
    languages: [ENGLISH]
  },
  {
    code: 'CH',
    name: 'Switzerland',
    region: 'Europe',
    languages: [GERMAN, FRENCH, ITALIAN]
  },
  {
    code: 'TR',
    name: 'Turkey',
    region: 'Europe',
    languages: [TURKISH]
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    region: 'Europe',
    languages: [ENGLISH]
  }
];

export const ASIA_PACIFIC_COUNTRIES: Country[] = [
  {
    code: 'MY',
    name: 'Malaysia',
    region: 'Asia Pacific',
    languages: [ENGLISH]
  },
  {
    code: 'SG',
    name: 'Singapore',
    region: 'Asia Pacific',
    languages: [ENGLISH, CHINESE]
  },
  {
    code: 'BN',
    name: 'Brunei',
    region: 'Asia Pacific',
    languages: [ENGLISH]
  },
  {
    code: 'PH',
    name: 'Philippines',
    region: 'Asia Pacific',
    languages: [ENGLISH]
  },
  {
    code: 'AU',
    name: 'Australia',
    region: 'Asia Pacific',
    languages: [ENGLISH]
  },
  {
    code: 'IN',
    name: 'India',
    region: 'Asia Pacific',
    languages: [ENGLISH]
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    region: 'Asia Pacific',
    languages: [ENGLISH]
  },
];

export const MIDDLE_EAST_AFRICA_COUNTRIES: Country[] = [
  {
    code: 'DZ',
    name: 'Algeria',
    region: 'Middle East & Africa',
    languages: [ARABIC, FRENCH]
  },
  {
    code: 'EG',
    name: 'Egypt',
    region: 'Middle East & Africa',
    languages: [ARABIC, ENGLISH]
  },
  {
    code: 'JO',
    name: 'Jordan',
    region: 'Middle East & Africa',
    languages: [ARABIC, ENGLISH]
  },
  {
    code: 'KE',
    name: 'Kenya',
    region: 'Middle East & Africa',
    languages: [ENGLISH]
  },
  {
    code: 'KW',
    name: 'Kuwait',
    region: 'Middle East & Africa',
    languages: [ARABIC, ENGLISH]
  },
  {
    code: 'LB',
    name: 'Lebanon',
    region: 'Middle East & Africa',
    languages: [ARABIC, ENGLISH, FRENCH]
  },
  {
    code: 'MA',
    name: 'Morocco',
    region: 'Middle East & Africa',
    languages: [ARABIC, FRENCH]
  },
  {
    code: 'OM',
    name: 'Oman',
    region: 'Middle East & Africa',
    languages: [ARABIC, ENGLISH]
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    region: 'Middle East & Africa',
    languages: [ARABIC, ENGLISH]
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    region: 'Middle East & Africa',
    languages: [ARABIC, ENGLISH]
  }
];

export const DEFAULT_LANGUAGE = SPANISH;

export const DEFAULT_COUNTRY: Country = {
  code: 'DO',
  name: 'Dominican Republic',
  region: 'Americas',
  languages: [SPANISH]
};

export const REDIRECT_DELAY = 10;

export const featuredIcons = {
  [DocumentCategory.GETTING_STARTED]: GettingStartedIcon,
  [DocumentCategory.PRODUCTS]: ProductResourcesIcon,
  [DocumentCategory.FEEL_GREAT]: FeelGreatPackIcon,
  [DocumentCategory.DOCUMENTS]: DocumentsIcon,
  [DocumentCategory.EVENTS]: EventsIcon,
  [DocumentCategory.VIDEOS]: VideosIcon,
  [DocumentCategory.UNICITY_BRANDING]: ProductResourcesIcon,
} as const;

const UNICITY_BRANDING = 'UNICITY_BRANDING';
export const tabsAndSectionsTranslationKeys = {
  [DocumentCategory.DOCUMENTS]: {
    tabTitle: 'documents',
    description: 'documents_description',
  },
  [DocumentCategory.FEEL_GREAT]: {
    categoryTitle: 'feel_great_pack',
    tabTitle: 'feel_great',
    description: 'feel_great_description',
  },
  [DocumentCategory.PRODUCTS]: {
    categoryTitle: 'product_resources',
    tabTitle: 'products',
    description: 'product_description'
  },
  [DocumentCategory.EVENTS]: {
    categoryTitle: 'events',
    tabTitle: 'events',
    description: 'events_description',
  },
  [UNICITY_BRANDING]: {
    tabTitle: 'unicity_branding',
    description: 'unicity_branding_description',
  },
  [DocumentCategory.VIDEOS]: {
    tabTitle: 'videos',
    description: 'videos_description',
  },
  [DocumentCategory.GETTING_STARTED]: {
    categoryTitle: 'getting_started',
    description: 'getting_started_description',
  }
} as const;

export const categoryTranslationKeys = {
  [DocumentCategory.GETTING_STARTED]: 'getting_started',
  [DocumentCategory.PRODUCTS]: 'products',
  [DocumentCategory.FEEL_GREAT]: 'feel_great',
  [DocumentCategory.EVENTS]: 'events',
  [DocumentCategory.VIDEOS]: 'videos',
  [DocumentCategory.UNICITY_BRANDING]: 'unicity_branding',
  [DocumentCategory.DOCUMENTS]: 'documents',
} as const;