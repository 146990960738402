import React, { useContext } from "react";
import { T, TM } from "../../hooks/useTranslate";

import { MenuItem, FormControl, Select, InputLabel } from "@mui/material"

import { CenturionContext } from "../../hooks/CenturionContext";
import { useMixPanel } from "../../hooks/useMixPanel";
import SubheaderBox from "../../components/SubheaderBox";

const CenturionMonthSelect = ({ isDashboard }) => {
  const { status, selectedMonth, contextMonthRange, setSelectedMonth, isError } = useContext(CenturionContext);
  const { logEvent } = useMixPanel();

  const onSelectMonth = (e) => {
    logEvent("centurion_dashboard_month_select", { month: e.target.value });
    setSelectedMonth(e.target.value);
  }

  const onOpen = () => {
    logEvent("centurion_dashboard_month_dropdown_open");
  }

  return (
    <FormControl
      id="month-dropdown-form"
      fullWidth
      sx={{ minWidth: "150px", maxWidth: isDashboard ? "150px" : null }}
    >
      {isDashboard && <InputLabel id="month-label" sx={{ fontSize: "14px" }}><T>month</T></InputLabel>}
      <Select
        labelId="month-dropdown-label"
        id="month-dropdown-select"
        label={isDashboard ? "Month" : null}
        value={selectedMonth}
        onChange={onSelectMonth}
        onOpen={onOpen}
        fullWidth
        renderValue={(i) => {
          if (isError) {
            return "-"
          }
          const selected = contextMonthRange.filter((month) => month.value === i)[0];
          return <TM>{selected.label}</TM>
        }}
        disabled={status !== "complete" || isError}
        inputProps={{
          sx: {
            fontSize: isDashboard ? "12px" : "14px",
            fontWeight: isDashboard ? "500" : "600",
            padding: isDashboard ? "8px 16px 8px 16px" : "9px 32px 9px 12px"
          }
        }}
      >
        <SubheaderBox>
          SELECT PERIOD
        </SubheaderBox>
        {contextMonthRange.map(item => {
          return (
            <MenuItem
              value={item.value}
              key={item.value}
              data-test-id={`centurion-month-dropdown-listitem`}
            >
              <TM>{item.label}</TM>
              {selectedMonth === item.value && <img src="icons/check.png" alt="checkmark" />}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  )
}

export { CenturionMonthSelect }