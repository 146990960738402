export enum DocumentCategory {
  DOCUMENTS = 'DOCUMENTS',
  EVENTS = 'EVENTS',
  FEEL_GREAT = 'FEEL_GREAT',
  PRODUCTS = 'PRODUCTS',
  UNICITY_BRANDING = 'UNICITY_BRANDING',
  VIDEOS = 'VIDEOS',
  GETTING_STARTED = 'GETTING_STARTED',
}

export enum TabsCategory {
  DOCUMENTS = 'DOCUMENTS',
  EVENTS = 'EVENTS',
  FEEL_GREAT = 'FEEL_GREAT',
  PRODUCTS = 'PRODUCTS',
  UNICITY_BRANDING = 'UNICITY_BRANDING',
}

export interface TabsCategoryItem {
  id: TabsCategory;
  tabTitle: string;
  description?: string;
  translationKey: string;
}

export interface Language {
  code: string;
  name: string;
  nameEnglish: string;
}

export interface Country {
  code: string;
  name: string;
  region: Region;
  languages: Language[];
}

export interface Document {
  id: string;
  title: {
    [key: string]: string;  // Localized titles
  };
  description: {
    [key: string]: string;  // Localized descriptions
  };
  fileName: string;
  fileType: string;
  mimeType: string;
  categories: DocumentCategory[];  // Already matches our enum
  tags: string[];
  languages: string[];
  markets: string[];
  isFeatured: boolean;
  status: string;
  version: string;
  uploadedBy: string;
  fileUrl: string;
  thumbnailUrl: string;
  lastUpdated: string;
}

export enum DocumentFileType {
  PDF = 'pdf',
  WORD = 'word',
  EXCEL = 'excel',
  POWERPOINT = 'powerpoint',
  IMAGE = 'image',
  VIDEO = 'video'
}

export interface DocumentFilter {
  search?: string;
  category?: DocumentCategory;
  tags?: string[];
}

export interface FeaturedTag {
  id: string;
  name: string;
  description?: string;
  thumbnailUrl?: string;
  lastUpdated: string;
}

export type Region = 'Americas' | 'Europe' | 'Asia Pacific' | 'Middle East & Africa';

export interface FeaturedSection {
  title: string;
  description?: string;
  updated: string;
  thumbnailURL: string;
  categories: string[];
}

export interface FeaturedContent {
  desktop: {
    main: {
      title: string;
    };
    featured: FeaturedSection[];
  };
  mobile: {
    main: {
      title: string;
    };
    featured: FeaturedSection[];
  };
}

export interface SelectedCategoryInfo {
  category?: DocumentCategory;
  categories: DocumentCategory[] | undefined;
  title?: string;
  properties?: {
    categories: DocumentCategory[] | undefined;
    title: string;
    description?: string;
  };
}

export interface UseLibraryOptions {
  category?: DocumentCategory;
  search?: string;
  market?: string;
  language?: string;
}

export interface LibraryState {
  documents: Document[];
  featuredContent: FeaturedContent | null;
  tabsCategory: TabsCategoryItem[];
}

export interface MarketPreferences {
  country: Country;
  language: Language;
} 