export const THEME_COLORS = {
  // Primary Colors
  PRIMARY_MAIN: 'rgba(4, 158, 207, 1)',
  WHITE: 'rgba(255, 255, 255, 1)',
  BLACK: 'rgba(0, 0, 0, 1)',

  // Light Theme Colors
  LIGHT: {
    TEXT_PRIMARY: 'rgba(21, 56, 98, 1)',
    BACKGROUND_DEFAULT: 'rgba(249, 250, 251, 1)',
    BACKGROUND_PAPER: 'rgba(255, 255, 255, 1)',
    STACK_BACKGROUND: 'rgba(255, 255, 255, 1)',
    
    SEVERITY: {
      ERROR: 'rgba(255, 242, 242, 1)',
      WARNING: 'rgba(145, 115, 209, 1)',
      INFO: 'rgba(0, 158, 207, 1)',
      SUCCESS: 'rgba(213, 240, 223, 1)',
      DEFAULT: 'rgba(255, 255, 255, 1)'
    },

    LEGS: {
      LEG1: {
        COLOR1: 'rgba(67, 192, 111, 1)',
        COLOR2: 'rgba(213, 240, 223, 1)'
      },
      LEG2: {
        COLOR1: 'rgba(0, 158, 207, 1)',
        COLOR2: 'rgba(217, 240, 248, 1)'
      },
      LEG3: {
        COLOR1: 'rgba(145, 115, 209, 1)',
        COLOR2: 'rgba(238, 232, 252, 1)'
      }
    },

    BORDER: {
      DATAGRID: 'rgba(237, 239, 241, 1)',
      TEXT_FIELD: 'rgba(145, 158, 171, 0.2)'
    },

    HOVER: {
      DATAGRID_ROW: 'rgba(0, 55, 100, 0.04)',
      LINK: 'rgba(0, 158, 207, 1)'
    },

    CHIP: {
      DELIVERED: {
        BACKGROUND: 'rgba(0, 55, 100, 0.11)',
        COLOR: 'rgba(0, 55, 100, 1)',
      },
      BACKORDERED: {
        BACKGROUND: 'rgba(0, 55, 100, 0.11)',
        COLOR: 'rgba(0, 55, 100, 1)',
      },
      RETURNED: {
        BACKGROUND: 'rgba(0, 55, 100, 0.11)',
        COLOR: 'rgba(0, 55, 100, 1)',
      },
      CANCELED: {
        BACKGROUND: 'rgba(100, 0, 55, 0.11)',
        COLOR: 'rgba(100, 0, 51, 1)',
      },
      SUBSCRIBED: {
        BACKGROUND: 'rgba(230, 245, 250, 1)',
        COLOR: 'rgba(0, 158, 207, 1)',
      },
      ONE_TIME_PURCHASE: {
        BACKGROUND: 'rgba(236, 249, 241, 1)',
        COLOR: 'rgba(67, 192, 111, 1)',
      },
      RANK_1: {
        BACKGROUND: 'rgba(145, 158, 171, 0.15)',
        COLOR: 'rgba(0, 0, 0, 1)',
      },
      RANK_2: {
        BACKGROUND: 'rgba(145, 158, 171, 0.15)',
        COLOR: 'rgba(0, 0, 0, 1)',
      },
      RANK_3: {
        BACKGROUND: 'rgba(237, 239, 241, 1)',
        COLOR: 'rgba(21, 56, 98, 1)',
      },
      RANK_4: {
        BACKGROUND: 'rgba(237, 239, 241, 1)',
        COLOR: 'rgba(21, 56, 98, 1)',
      },
      RANK_5: {
        BACKGROUND: 'rgba(237, 239, 241, 1)',
        COLOR: 'rgba(21, 56, 98, 1)',
      },
      RANK_6: {
        BACKGROUND: 'rgba(227, 245, 234, 1)',
        COLOR: 'rgba(67, 192, 111, 1)',
      },
      RANK_7: {
        BACKGROUND: 'rgba(227, 245, 234, 1)',
        COLOR: 'rgba(67, 192, 111, 1)',
      },
      RANK_8: {
        BACKGROUND: 'rgba(227, 245, 234, 1)',
        COLOR: 'rgba(67, 192, 111, 1)',
      },
      RANK_9: {
        BACKGROUND: 'rgba(217, 241, 248, 1)',
        COLOR: 'rgba(0, 158, 207, 1)',
      },
      RANK_10: {
        BACKGROUND: 'rgba(217, 241, 248, 1)',
        COLOR: 'rgba(0, 158, 207, 1)',
      },
      RANK_11: {
        BACKGROUND: 'rgba(217, 241, 248, 1)',
        COLOR: 'rgba(0, 158, 207, 1)',
      },
      RANK_12: {
        BACKGROUND: 'rgba(245, 234, 243, 1)',
        COLOR: 'rgba(186, 113, 174, 1)',
      },
    }
  },

  // Dark Theme Colors
  DARK: {
    TEXT_PRIMARY: 'rgba(255, 255, 255, 1)',
    BACKGROUND_DEFAULT: 'rgba(6, 5, 4, 1)',
    BACKGROUND_PAPER: 'rgba(18, 18, 18, 0.99)',
    STACK_BACKGROUND: 'rgba(18, 18, 18, 0.99)',
    
    SEVERITY: {
      ERROR: 'rgba(50, 20, 20, 1)',
      WARNING: 'rgba(80, 65, 115, 1)',
      INFO: 'rgba(0, 88, 117, 1)',
      SUCCESS: 'rgba(33, 60, 43, 1)',
      DEFAULT: 'rgba(18, 18, 18, 0.9)'
    },

    LEGS: {
      LEG1: {
        COLOR1: 'rgba(213, 240, 223, 1)',
        COLOR2: 'rgba(67, 192, 111, 1)'
      },
      LEG2: {
        COLOR1: 'rgba(217, 240, 248, 1)',
        COLOR2: 'rgba(0, 158, 207, 1)'
      },
      LEG3: {
        COLOR1: 'rgba(238, 232, 252, 1)',
        COLOR2: 'rgba(145, 115, 209, 1)'
      }
    },

    BORDER: {
      DATAGRID: 'rgba(81, 81, 81, 1)',
      TEXT_FIELD: 'rgba(255, 255, 255, 0.23)'
    },

    HOVER: {
      DATAGRID_ROW: 'rgba(255, 255, 255, 0.04)',
      LINK: 'rgba(0, 158, 207, 1)'
    },

    COMPONENT: {
      DATAGRID_HEADER: 'rgba(45, 45, 45, 1)'
    },

    CHIP: {
      DELIVERED: {
        BACKGROUND: 'rgba(0, 55, 100, 1)',
        COLOR: 'rgba(255, 255, 255, 1)',
      },
      BACKORDERED: {
        BACKGROUND: 'rgba(0, 55, 100, 1)',
        COLOR: 'rgba(255, 255, 255, 1)',
      },
      RETURNED: {
        BACKGROUND: 'rgba(0, 55, 100, 1)',
        COLOR: 'rgba(255, 255, 255, 1)',
      },
      CANCELED: {
        BACKGROUND: 'rgba(100, 0, 55, 1)',
        COLOR: 'rgba(255, 255, 255, 1)',
      },
      RANK_1: {
        BACKGROUND: 'rgba(145, 158, 171, 1)',
        COLOR: 'rgba(255, 255, 255, 1)',
      },
      RANK_2: {
        BACKGROUND: 'rgba(145, 158, 171, 1)',
        COLOR: 'rgba(255, 255, 255, 1)',
      },
      RANK_3: {
        BACKGROUND: 'rgba(21, 56, 98, 1)',
        COLOR: 'rgba(237, 239, 241, 1)',
      },
      RANK_4: {
        BACKGROUND: 'rgba(21, 56, 98, 1)',
        COLOR: 'rgba(237, 239, 241, 1)',
      },
      RANK_5: {
        BACKGROUND: 'rgba(21, 56, 98, 1)',
        COLOR: 'rgba(237, 239, 241, 1)',
      },
      RANK_6: {
        BACKGROUND: 'rgba(67, 192, 111, 1)',
        COLOR: 'rgba(227, 245, 234, 1)',
      },
      RANK_7: {
        BACKGROUND: 'rgba(67, 192, 111, 1)',
        COLOR: 'rgba(227, 245, 234, 1)',
      },
      RANK_8: {
        BACKGROUND: 'rgba(67, 192, 111, 1)',
        COLOR: 'rgba(227, 245, 234, 1)',
      },
      RANK_9: {
        BACKGROUND: 'rgba(0, 158, 207, 1)',
        COLOR: 'rgba(217, 241, 248, 1)',
      },
      RANK_10: {
        BACKGROUND: 'rgba(0, 158, 207, 1)',
        COLOR: 'rgba(217, 241, 248, 1)',
      },
      RANK_11: {
        BACKGROUND: 'rgba(0, 158, 207, 1)',
        COLOR: 'rgba(217, 241, 248, 1)',
      },
      RANK_12: {
        BACKGROUND: 'rgba(186, 113, 174, 1)',
        COLOR: 'rgba(245, 234, 243, 1)',
      },
      SUBSCRIBED: {
        BACKGROUND: 'rgba(0, 158, 207, 1)',
        COLOR: 'rgba(255, 255, 255, 1)',
      },
      ONE_TIME_PURCHASE: {
        BACKGROUND: 'rgba(67, 192, 111, 1)',
        COLOR: 'rgba(255, 255, 255, 1)',
      },
    }
  }
} as const;

export type ThemeMode = 'light' | 'dark';
