export const styles = {
  dateText: {
    fontWeight: "500",
    fontSize: ".625rem",
    opacity: ".6"
  },
  nameText: {
    fontWeight: "600",
    fontSize: ".75rem",
    "&:hover": {
      color: "#0da3d2"
    }
  },
  newsItem: {
    cursor: "pointer",
  },
  articleViewItemName: {
    fontWeight: "600",
    fontSize: "1.125rem",
  },
  articleViewItemDate: {
    fontWeight: "500",
    fontSize: ".75rem",
    opacity: ".6",
    marginTop: "2px"
  },
  skeletonGridItem: {
    borderRadius: ".75rem"
  },
  readMoreButton: {
    height: "35px",
    color: "#153862",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "1px solid rgba(145, 158, 171, 0.20)",
    padding: "4px 4px 4px 12px",
    "&:hover": {
      opacity: ".9",
      backgroundColor: "#fff"
    }
  },
  readMoreButtonText: {
    fontWeight: "600",
    fontSize: ".75rem",
    textTransform: "none"
  },
};