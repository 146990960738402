import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Drawer } from "@mui/material";

import { DistributorContext } from "../../../hooks/DistributorContext";
import { useStack } from "../../../hooks/useStack";
import { MemberContext } from "../../../hooks/MemberContext";
import { DistributorProfileDrawerContent } from "./DistributorProfileDrawerContent";
import { OrderSummaryDrawerContent } from "../../../components/drawer/OrderSummaryDrawerContent";
import { OrderDetailDrawerContent } from "../../../components/drawer/OrderDetailDrawerContent";
import { ReferralSummaryDrawerContent } from "../../../components/drawer/ReferralSummaryDrawerContent";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerContentComponents = {
  profile: DistributorProfileDrawerContent,
  orders: OrderSummaryDrawerContent,
  orderDetails: OrderDetailDrawerContent,
  referrals: ReferralSummaryDrawerContent,
};


const DistributorsDrawer = () => {
  const { isDrawerOpen, setIsDrawerOpen, setDistributorData, resetContext: resetDistributorContext, } = useContext(DistributorContext);
  const { setMemberId, clearErrorMessage, distributorData, resetContext: resetMemberContext } = useContext(MemberContext);
  const [drawerContent, setDrawerContent] = useState(null);
  const [orderHref, setOrderHref] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const profileViewStack = useStack();

  const theme = useTheme();
  const viewportIsSmall = !useMediaQuery(theme.breakpoints.up("sm"));
  const DrawerContentComponent = drawerContentComponents[drawerContent];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const drawer = searchParams.get("drawer");
    if (drawer === "partner") {
      setIsDrawerOpen(true);
      setDrawerContent(searchParams.get("drawerContent"));
      setMemberId(searchParams.get("id"));
      // push id onto profileViewStack if id is in URL and is not already in the stack
      searchParams.has("id") && !profileViewStack.contains(searchParams.get("id")) && profileViewStack.push(searchParams.get("id"));
      setOrderHref(searchParams.get("orderHref"));
    } else {
      setIsDrawerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (Object.keys(distributorData).length > 0) {
      setDistributorData(distributorData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorData])

  const handleClose = () => {
    navigate(location.pathname, { replace: true });
    setIsDrawerOpen(false);
    resetDistributorContext();
    resetMemberContext();
    clearErrorMessage();
    profileViewStack.clear();
  }

  return (
    <div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleClose}
      >
        <Box
          sx={{
            width: "calc(100vw - 16px)",
            maxWidth: !viewportIsSmall ? 400 : null,
            padding: 0,
          }}
          role="presentation"
        >
          {DrawerContentComponent && (
            <DrawerContentComponent
              setIsDrawerOpen={setIsDrawerOpen}
              orderHref={orderHref}
              profileViewStack={profileViewStack}
            />
          )}
        </Box>
      </Drawer>
    </div>
  );
};

export { DistributorsDrawer };
