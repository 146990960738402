import React from "react";
import { T } from "../../../../hooks/useTranslate";

import { Stack, Typography } from "@mui/material";

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { UpdatedDate } from "../../../../components/UpdatedDate";

const CenturionSection = ({ currentCentCount, centurionDateCalculated }) => {
  const progressValue = currentCentCount > 99 ? 100 : currentCentCount;

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#FFE4DB",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#FFAD91",
    },
  }));

  return (
    <Stack bgcolor="#919EAB0D" borderRadius={1}>
      <Stack margin="16px 16px 0px 16px">
        <UpdatedDate date={centurionDateCalculated} />
      </Stack>
      <Stack p={2} gap={2}>
        <Typography
          data-test-id="centurion-header"
          variant="h3"
        >
          <T>centurion</T>
        </Typography>
        <BorderLinearProgress
          variant="determinate"
          value={progressValue}
          data-test-id="centurion-progress-bar"
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography
            data-test-id="centurion-value"
            sx={{
              fontWeight: "600",
              fontSize: "11px",
            }}
          >
            {currentCentCount}
          </Typography>
          <Typography
            variant="h9"
            sx={{ opacity: ".6" }}
          >
            &nbsp;/&nbsp;<span data-test-id="centurion-required-value">{100}</span>
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export { CenturionSection }