import { createTheme, Theme } from "@mui/material/styles";
import { SystemStyleObject } from '@mui/system';
import { THEME_COLORS } from "./themeColors";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    severity?: {
      error: string;
      warning: string;
      info: string;
      success: string;
      default: string;
    };
  }
  interface Components {
    MuiPickersDay?: {
      styleOverrides?: {
        root?: SystemStyleObject;
      };
    };
  }
}

const createDarkTheme = (): Theme =>
  createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: THEME_COLORS.PRIMARY_MAIN,
        stackBackground: THEME_COLORS.DARK.STACK_BACKGROUND,
      },
      text: {
        primary: THEME_COLORS.DARK.TEXT_PRIMARY,
      },
      background: {
        default: THEME_COLORS.DARK.BACKGROUND_DEFAULT,
        paper: THEME_COLORS.DARK.BACKGROUND_PAPER,
      },
      severity: {
        error: THEME_COLORS.DARK.SEVERITY.ERROR,
        warning: THEME_COLORS.DARK.SEVERITY.WARNING,
        info: THEME_COLORS.DARK.SEVERITY.INFO,
        success: THEME_COLORS.DARK.SEVERITY.SUCCESS,
        default: THEME_COLORS.DARK.SEVERITY.DEFAULT
      },
    },
    typography: {
      fontFamily: "Inter",
      button: {
        textTransform: "none",
      },
      h2: {
        fontWeight: "600",
        fontSize: "16px"
      },
      h3: {
        fontWeight: "600",
        fontSize: "14px"
      },
      h4: {
        fontWeight: "600",
        fontSize: "12px"
      },
      h5: {
        fontWeight: "400",
        fontSize: "12px"
      },
      h6: {
        fontWeight: "600",
        fontSize: "10px",
        opacity: ".6"
      },
      h7: {
        fontWeight: "400",
        fontSize: "12px",
        opacity: ".6"
      },
      h8: {
        fontWeight: "400",
        fontSize: "14px"
      },
      h9: {
        fontWeight: "500",
        fontSize: "11px"
      },
      h10: {
        fontWeight: "600",
        fontSize: "10px",
        opacity: ".5"
      },
      h11: {
        fontWeight: "400",
        fontSize: "10px",
        color: "#919EAB"
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "body": {
            minHeight: "100vh",
            backgroundColor: THEME_COLORS.DARK.BACKGROUND_DEFAULT,
          },
          "html[data-theme='dark']": {
            backgroundColor: THEME_COLORS.DARK.BACKGROUND_DEFAULT,
          },
          "& .unicity-logo": {
            filter: "brightness(400%) saturate(0) contrast(100%)",
            opacity: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          ".MuiListItem-root, .MuiMenuItem-root": {
            "& img[src$='.svg']": {
              filter: "brightness(400%) saturate(0) contrast(100%)",
              opacity: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            "& .MuiButtonBase-root.Mui-selected": {
              "& img[src$='.svg']": {
                filter: "brightness(100%) saturate(100%) contrast(100%)",
                opacity: "1"
              }
            }
          },
          "&img[src$='.svg'].svg-boost": {
            filter: "brightness(300%)",
            opacity: "1"
          },
          "&.unicity-office-logo": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            "& svg": {
              filter: "brightness(400%) saturate(0) contrast(100%)",                            
            },
            "& .unicity-office-logo-text": {
              color: "#FFFFFF",
              fontSize: "1.25em",
              fontWeight: 450,
            },
          },
          "&.search-popper": {
            backgroundColor: "rgba(18, 18, 18, 0.9)",
            border: "1px solid rgba(145, 158, 171, 0.10)",
            filter: "drop-shadow(0px 1px 3px rgba(145, 158, 171, 0.10)) drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.08))",
            borderRadius: "8px",
          },
          "& svg": {          
            "&.help-icon": {
              width: "18px",
              height: "18px",
            },  
            "&.search-glass-icon, &.arrow-right-icon, &.arrow-left-icon, &.help-icon, &.notice-icon, &.phone-icon, &.message-icon, &.mail-icon, &.phone-disabled-icon, &.message-disabled-icon, &.mail-disabled-icon, &.mail-disabled-icon, &.message-disabled-icon, &.alert-close-icon, &.upgrade-icon, &.library-icon, &.download-icon, &.download-disabled-icon, &.content-copy-icon, &.close-icon, &.menu-icon, &.add-icon, &.external-icon, &.downline-icon, &.info-icon, &.auto-renew-icon, &.subscription-grey-icon, &.chevron-right-icon, &.genealogy-icon": {
              fill: "none",
              stroke: "#FFFFFF",
            },
            "&.globe-icon": {
              fill: "#FFFFFF",
              stroke: "none",
            },
            "&.auto-renew-icon, &.subscription-grey-icon, &.chevron-right-icon, &.subscription-grey-icon, &.chevron-right-icon": {
              fill: "none",
              stroke: "none",
            },
            "&.item-image-placeholder-icon, &.credit-card-icon, &.delivery-truck-icon, &.shipping-icon, &.box-icon, &.square-poll-icon": {
              filter: "brightness(400%) saturate(0) contrast(100%)",
              opacity: "1"
            },
          },
          "input:-webkit-autofill, input:-internal-autofill-selected": {
            WebkitBoxShadow: "0 0 0px 1000px transparent inset !important",
            transition: "background-color 5000s ease-in-out 0s !important",
            color: "#FFFFFF",
          },
          "&.order-history": {
            "& .order-history-header": {
              display: "flex",
              justifyContent: "space-between",
              border: "2px solid #333333",
              borderRadius: "12px 12px 0  0",
              padding: "5px 16px",
            },
            "& .order-history-items": {
              border: "1px solid #333333",
              borderRadius: "0  0 12px 12px",
              borderTop: "none",
              padding: "16px",
              "& .order-history-items-list": {
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingBottom: "20px",
                "& .order-history-item": {
                  display: "flex", 
                  flexDirection: "row", 
                  gap: "10px", 
                  justifyContent: "space-between", 
                  paddingTop: "20px",
                  alignItems: "center",
                  "& .order-history-item-image": {
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "space-between",
                    width: "fit-content",
                    "& img": {
                      width: "40px",
                      objectFit: "contain",
                    }
                  },
                  "& .order-history-item-details": {
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    justifyContent: "space-between",
                  }
                },
              },
              "& hr": {
                borderColor: "#333333",
              },
              "& .order-history-item-footer": {
                display: "flex", 
                flexDirection: "row", 
                gap: "10px", 
                justifyContent: "space-between", 
                paddingTop: "16px",
              }
            }
          },
          "& .order-details-screen": {
            "& .order-details-screen-content": {
              borderRadius: "12px 12px 0  0",
              borderColor: "#333333",
            },
            "& .order-details-screen-body": {
              borderRadius: "0  0 12px 12px",
              borderTop: "none",
              borderColor: "#333333",
            },
            "& .order-details-screen-header": {
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            },
            "& .order-history-items-list": {
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              paddingBottom: "20px",
              "& .order-history-item": {
                display: "flex", 
                flexDirection: "row", 
                gap: "10px", 
                justifyContent: "space-between", 
                paddingTop: "20px",
                "& .order-history-item-image": {
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  justifyContent: "space-between",
                  width: "fit-content",
                  "& img": {
                    width: "40px",
                    objectFit: "contain",
                  }
                },
                "& .order-history-item-details": {
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  justifyContent: "space-between",
                }
              },
            },
          }
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            "&.u-chip": {
              display: "flex",
              alignItems: "center",
              gap: '2px',
              borderRadius: '38px',
              whiteSpace: 'nowrap',
              height: "20px",
              width: "fit-content",
              userSelect: "none",
              "& .MuiChip-label": {
                padding: "1px 10px",
                display: "flex",
              },
              "& .MuiTypography-root": {
                textAlign: "center",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                fontFamily: "Inter",
                whiteSpace: 'nowrap',
              },
              "& .MuiChip-avatar": {
                marginLeft: "1px",
                marginRight: "-6px",
                width: "auto",
                height: "20px",
                fontSize: "10px",
                fontWeight: "600",
                whiteSpace: 'nowrap',
                color: "#FFF",
                backgroundColor: "rgba(1, 1, 1, 0.85)",
              },
              "& .MuiChip-label .MuiTypography-root": { 
                fontFamily: "Inter",
                fontSize: "10px",
                fontWeight: "600"
              },
              "& .MuiChip-icon": {
                marginLeft: "1px",
                "&.auto-renew-icon": {
                  filter: "brightness(150%) saturate(0) contrast(100%)",
                  opacity: "1"
                },
                "&.subscription-grey-icon": {
                  filter: "brightness(150%) saturate(0) contrast(100%)",
                  opacity: "0.5"
                }
              },
              "& .MuiChip-deleteIcon": {
                marginRight: "1px",
              },
              "&.large": {
                padding: "5px 10px",
                height: "30px",
                "& .MuiChip-label .MuiTypography-root": {
                  fontSize: "12px",
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                }
              }
            }
          }
        },
        variants: [
          {
            props: { className: 'u-chip', variant: 'leg1-holder' },
            style: {
              backgroundColor: "#43C06F",
              color: "rgba(255, 255, 255, 0.9)",
            },
          },
          {
            props: { className: 'u-chip', variant: 'leg2-holder' },
            style: {
              backgroundColor: "#009ECF",
              color: "rgba(255, 255, 255, 0.9)",
            },
          },
          {
            props: { className: 'u-chip', variant: 'member' },
            style: {
              backgroundColor: "#153862",
              color: "#EDEFF1",
            },
          },
          {
            props: { className: 'u-chip', variant: 'suspended' },
            style: {
              backgroundColor: "#DF4747",
              color: "#FFFFFF",
            },
          },
          {
            props: { className: 'u-chip', variant: 'inactive' },
            style: {
              backgroundColor: "#153862",
              color: "#EDEFF1",
            },
          },
          {
            props: { className: 'u-chip', variant: 'avatar' },
            style: {
              backgroundColor: "#060504",
              color: "#EDEFF1",
              fontSize: "10px",
              fontWeight: "600",
              height: "30px !important",
              "& .MuiAvatar-root": {
                marginLeft: "5px",
                marginRight: "-6px",
                width: "24px",
                height: "24px",
                fontSize: "0.75rem",
            }
            },
          },
          /** < statusTable */
          {
            props: { className: 'u-chip', variant: 'Fulfilled' },
            style: {
              color: "#FFFFFF",
              backgroundColor: "rgba(0, 100, 55, 1)",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Cancelled' },
            style: {
              color: "#FFFFFF",
              backgroundColor: "rgba(100, 0, 55, 1)",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'ReadyToProcess' },
            style: {
              color: "#FFFFFF",
              backgroundColor: "rgba(200, 138, 2, 1)",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Processing' },
            style: {
              color: "#FFFFFF",
              backgroundColor: "rgba(200, 138, 2, 1)",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Unfulfilled' },
            style: {
              color: "#FFFFFF",
              backgroundColor: "rgba(100, 0, 55, 1)",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Hold' },
            style: {
              color: "#FFFFFF",
              backgroundColor:"rgba(0, 55, 100, 1)",
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Delivered' },
            style: {
              color: THEME_COLORS.DARK.CHIP.DELIVERED.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.DELIVERED.BACKGROUND,
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Backordered' },
            style: {
              color: THEME_COLORS.DARK.CHIP.BACKORDERED.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.BACKORDERED.BACKGROUND,
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Returned' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RETURNED.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RETURNED.BACKGROUND,
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'Canceled' },
            style: {
              color: THEME_COLORS.DARK.CHIP.CANCELED.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.CANCELED.BACKGROUND,
              fontSize: "10px",
              fontWeight: "600",
              width: "fit-content",
            },
          },
          /** / statusTable > */
          /** < autoship status */
          {
            props: { className: 'u-chip', variant: 'subscribed' },
            style: {
              color: THEME_COLORS.DARK.CHIP.SUBSCRIBED.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.SUBSCRIBED.BACKGROUND,
              height: "20px", 
              fontSize: "10px", 
              fontWeight: "600", 
              width: "fit-content",
            },
          },
          {
            props: { className: 'u-chip', variant: 'one_time_purchase' },
            style: {
              backgroundColor: THEME_COLORS.DARK.CHIP.ONE_TIME_PURCHASE.BACKGROUND,
              color: THEME_COLORS.DARK.CHIP.ONE_TIME_PURCHASE.COLOR,
              height: "20px", 
              fontSize: "10px", 
              fontWeight: "600", 
              width: "fit-content",
            },
          },
          /** / autoship status > */          
          /** < rank chips */
          {
            props: { className: 'u-chip', variant: 'rank-1' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_1.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_1.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-2' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_2.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_2.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-3' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_3.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_3.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-4' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_4.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_4.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-5' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_5.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_5.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-6' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_6.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_6.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-7' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_7.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_7.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-8' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_8.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_8.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-9' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_9.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_9.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-10' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_10.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_10.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-11' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_11.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_11.BACKGROUND,
            },
          },
          {
            props: { className: 'u-chip', variant: 'rank-12' },
            style: {
              color: THEME_COLORS.DARK.CHIP.RANK_12.COLOR,
              backgroundColor: THEME_COLORS.DARK.CHIP.RANK_12.BACKGROUND,
            },
          },
          /** / rank chips > */
          /** < customer type chip */
          {
            props: { className: 'u-chip', variant: 'type-member' },
            style: {
              backgroundColor: "#5A8FC3",
              color: "#FFFFFF",
            }
          },
          {
            props: { className: 'u-chip', variant: 'type-retail' },
            style: {
              backgroundColor: "#5A8FC3",
              color: "#FFFFFF",
            }
          },
          {
            props: { className: 'u-chip', variant: 'type-wholesale' },
            style: {
              backgroundColor: "#5A8FC3",
              color: "#FFFFFF",
            }
          },
          {
            props: { className: 'u-chip', variant: 'type-preferred' },
            style: {
              backgroundColor: "#5A8FC3",
              color: "#FFFFFF",
            }
          },
          /** / customer type chip > */
          /** < subscription chip */
          {
            props: { className: 'u-chip', variant: 'subscription-chip-subscribed' },
            style: {
              backgroundColor: "#009ECF",
              color: "#E6F5FA",
              }
          },
          {
            props: { className: 'u-chip', variant: 'subscription-chip-canceled' },
            style: {
              backgroundColor: "#FF9B79",
              color: "#FFF3EF",
              }
          },
          {
            props: { className: 'u-chip', variant: 'subscription-chip-default' },
            style: {
              backgroundColor: "#84909C",
              color: "#DDE1E5",              
            }
          },
          {
            props: { className: 'u-chip', variant: 'subscription-chip-one-time-purchase' },
            style: {
              backgroundColor: "#43C06F",
              color: "#ECF9F1",
              }
          },
          /** / subscription chip > */
          /** < sponsor enroller chip */
          {
            props: { className: 'u-chip large', variant: 'sponsor-enroller-chip' },
            style: {
              color: "rgba(255, 255, 255, 1)",
              backgroundColor: "rgba(145, 115, 209, 1)",
            }
          },
          /** / sponsor enroller chip > */
        ],
      },
      MuiModal: {
        styleOverrides: {
          root: {
            "& .common-modal": {
              backgroundColor: "rgba(18, 18, 18, 0.9)",
              height: "fit-content",
              position: "absolute",
              width: 400,
              boxShadow: 5,
              padding: "2rem",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "8px",
            }
          }
        }
      },      
      MuiStack: {
        styleOverrides: {
          root: {
            "&.notice-stack": {
              borderRadius: "8px",
              border: "1px solid rgba(255, 255, 255, 0.05)",
              backgroundColor: "rgba(255, 255, 255, 0.25)",
              "& img[src$='.svg']": {
                filter: "brightness(400%) saturate(0) contrast(100%)",
                opacity: "1"
              },
            },
            '&.login-form': {
              boxSizing: 'content-box',
              paddingTop: '3em',
              paddingBottom: '3em',
              flexDirection: 'column',
              gap: 0,
              width: '90vw',
              '@media (min-width: 0px)': {
                gap: '24px',
                paddingLeft: '5em',
                paddingRight: '5em',
              },
              '@media (min-width: 600px)': {
                gap: '32px',
                paddingLeft: '5em',
                paddingRight: '5em',
                width: '360px',
              },
              '@media (min-width: 900px)': {
                gap: '40px',
              },
              '& .login-form-buttons': {
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '16px',
                '@media (min-width: 600px)': {
                  flexDirection: 'column',
                  gap: '16px',
                },
                '@media (min-width: 900px)': {
                  flexDirection: 'row',
                  gap: '32px',
                },
              },
              "& input": {
                boxShadow: "none",
                "&:webkit-autofill": {
                  boxShadow: "none",                  
                },
              },        
            },
            '&.referral-link-box': {
              width: "auto",
              height: "auto",
              backgroundColor: '#060504',
              borderWidth: "1.9px",
              borderStyle: "dashed",
              borderColor: "#555555",
              marginTop: '25px',
              borderRadius: '8px',
            },
            "&.language-modal-desktop": {
              backgroundColor: "rgba(18, 18, 18, 0.9)",
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              border: '1px solid rgba(145, 158, 171, 0.10)',
              boxShadow: '0px 1px 3px 0px rgba(145, 158, 171, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.08)',
              borderRadius: "8px",
              padding: "20px"
            },
            "&.article-card": {
              backgroundColor: "rgba(18, 18, 18, 0.9)",
              border: "1px solid rgba(145, 158, 171, 0.10)",
              boxShadow: "0px 1px 3px 0px rgba(145, 158, 171, 0.10)",              
            }
          },
        },
        variants: [
          {
            props: { variant: 'error' },
            style: {
              "&.notice-stack": {
                borderRadius: "8px",
                border: "1px solid rgba(255, 0, 0, 0.05)",
                backgroundColor: "rgba(255, 0, 0, 0.25)",
              },
            },
          },
          {
            props: { variant: 'warning' },
            style: {
              "&.notice-stack": {
                borderRadius: "8px",
                border: "1px solid rgba(241, 182, 68, 0.05)",
                backgroundColor: "rgba(241, 182, 68, 0.25)",
              },
            },
          },
          {
            props: { variant: 'info' },
            style: {
              "&.notice-stack": {
                borderRadius: "8px",
                border: "1px solid rgba(0, 158, 207, 0.15)",
                backgroundColor: "rgba(0, 158, 207, 0.25)",
              },
            },
          },
          {
            props: { variant: 'success' },
            style: {
              "&.notice-stack": {
                borderRadius: "8px",
                border: "1px solid rgba(0, 128, 0, 0.05)",
                backgroundColor: "rgba(0, 128, 0, 0.25)",
              },
            },
          },
        ],
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "7px",
            fontWeight: "600",
            height: "45px",
            padding: "0px 1px 0px 1px",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
            "&.u-button": {
              color: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: "600",
              padding: "5px 12px 5px 12px",
              background: "#1E1E1E",
              border: "1px solid rgba(255, 255, 255, 0.10)",
              boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.20)",
              borderRadius: "8px",
              "& svg": {
                filter: "brightness(400%) saturate(0) contrast(100%)",
              },
              "&:hover": {
                cursor: "pointer"
              },
              "&[aria-disabled='true']": {                
                color: "#F1F4F7",
                background: "#919EAB",
                "&:hover": {
                  cursor: "not-allowed"
                }
              }                  
            },
            "&.language-switcher": {
                width: 148,
                justifyContent: "space-between",
                border: "1px solid rgba(145, 158, 171, 0.1)",
                boxShadow: "0px 1px 3px rgba(145, 158, 171, 0.1)",
                paddingRight: "10px",
                borderRadius: '12px',
                fontSize: 12, 
                fontWeight: "500",
                paddingLeft: "10px",
                color: "#FFFFFF",
                '@media (max-width: 600px)': {
                  borderRadius: '0',
                },
              }
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            border: "none",
            fontFamily: '"Inter", sans-serif',
            color: "primary.main",
            "& .MuiDataGrid-columnHeaders": {
              background: "none"
            },
            "& .MuiDataGrid-columnHeader": {
              background: "none"
            }
          },
          cell: {
            borderBottom: `1px solid ${THEME_COLORS.DARK.BORDER.DATAGRID}`,
            ":focus-within": {
              outline: "none",
            },
          },
          columnHeaders: {
            borderBottomColor: THEME_COLORS.DARK.BORDER.DATAGRID,
          },
          columnHeader: {
            border: "none",
            backgroundColor: THEME_COLORS.DARK.BACKGROUND_DEFAULT,
            letterSpacing: "-0.01em",
            ":focus-within": {
              outline: "none",
            },
          },
          "columnHeader--sorted": {
            textDecoration: "underline",
          },
          columnHeaderTitle: {
            fontWeight: 600,
          },
          columnSeparator: {
            display: "none",
          },
          footerContainer: {
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "stretch",
            justifyContent: "flex-start",
            borderTop: "1px solid rgba(237, 239, 241, 1)"
          },
          row: {
            ":hover": {
              backgroundColor: THEME_COLORS.DARK.HOVER.DATAGRID_ROW,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: THEME_COLORS.DARK.TEXT_PRIMARY,
            "&:hover": {
              color: THEME_COLORS.DARK.TEXT_PRIMARY,
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: THEME_COLORS.DARK.TEXT_PRIMARY,
            textDecorationColor: THEME_COLORS.DARK.TEXT_PRIMARY,
            ":hover:not(.resource-nav-item-link)": {
              color: THEME_COLORS.DARK.HOVER.LINK,
            },
            fontFamily: '"Inter", sans-serif',
            "&.com-button": {
              color: '#EDEFF1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: '600',
              padding: '8px',
              background: '#1E1E1E',                
              border: '1px solid rgba(255, 255, 255, 0.10)',
              boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
              borderRadius: '8px',
              userSelect: 'none',              
              "& svg": {
                filter: "brightness(400%) saturate(0) contrast(100%)",
                opacity: "1",
                fill: "#FFFFFF",  
              },
              '&:hover' : { 
                cursor: 'pointer',
                background: '#3D3D3D',
                border: '1px solid rgba(255, 255, 255, 0.05)',
                boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.20)',                
                '&[aria-disabled="true"]': {
                  cursor: 'not-allowed',
                },
              },
              '&[aria-disabled="true"]': {
                color: '#919EAB',
                background: '#2C2C2C',
                opacity: '.5',
                border: '1px solid rgba(255, 255, 255, 0.05)',
                boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
                cursor: 'not-allowed',
              },
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            opacity: "0.5",
            "&.Mui-focused": {
              opacity: "1",
            },
          },
          shrink: {
            fontFamily: "Inter",
            fontSize: "14px",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderRadius: "8px",
              border: `1px solid ${THEME_COLORS.DARK.BORDER.TEXT_FIELD}`,
            },
            "&:hover:not(:focus):not(:focus-within) fieldset": {
              border: `1px solid ${THEME_COLORS.DARK.BORDER.TEXT_FIELD} !important`,
            },
          },
        },
      },
      // Style dates on calendar
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontFamily: "Inter",
            "&:focus.Mui-selected": {
              backgroundColor: "rgb(4, 158, 207)",
            },
          },
        },
      },
      // style calendar container
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            padding: "24px 24px 24px 10px",
            color: "rgb(21, 56, 98)",
          },
        },
      },
      // style calendar outer container
      MuiPaper: {
        styleOverrides: {
          root: {
            "&.MuiPickersPopper-paper": {
              boxShadow: "0px 6px 12px rgba(145, 158, 171, 0.2)",
            },
          },
        },
      },
      // style calendar header
      MuiPickersCalendarHeader: {
        styleOverrides: {
          labelContainer: {
            fontFamily: "Inter",
          },
        },
      },
      // style M T W etc. in calendar
      MuiTypography: {
        styleOverrides: {
          root: {
            "&.MuiDayPicker-weekDayLabel": {
              fontFamily: "Inter",
            },
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            '&.subheader': {
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: 10,
              color: "#8A9BB0",
              marginTop: "8px",
              marginBottom: "8px",
              marginLeft: "24px"
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)",
            border: "1px solid rgba(145, 158, 171, 0.1)",
            borderRadius: "8px",
            marginTop: "5px",
            fontWeight: "500",
            fontSize: "12px",
            '&.Mui-selected': {
              justifyContent: "space-between",
              color: "rgba(0, 158, 207, 1)",
              backgroundColor: "rgba(0, 158, 207, 0.05)",
              fontWeight: "600",
              "&.MuiInputBase-inputSizeSmall": {
                fontFamily: "Inter",
                fontWeight: "600",
                fontSize: "11px",
                paddingTop: "5.5px",
                paddingBottom: "5.5px",
              }
            },
            '&.MuiMenuItem-root': {
              display: "flex",
              flexDirection: "row",
              minWidth: "230px",
              margin: 0,
              borderRadius: 0,
              border: "none",
              boxShadow: "none",
              justifyContent: "start",              
            },
            ".MuiListItem-root": {
              "& img[src$='.svg']": {
                filter: "invert(0) brightness(300%)",
                opacity: "0.8"
              }
            }
          },
        },
      },
      // style number value inside of pagination select tag
      MuiSelect: {
        styleOverrides: {
          root: {
            fontWeight: "500",
            fontSize: "14px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: 'inherit',
            },
            "&.MuiInputBase-inputSizeSmall": {
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "11px",
              paddingTop: "5.5px",
              paddingBottom: "5.5px",
            },
            '& .MuiInputBase-input': {
              fontSize: "12px",
              fontWeight: "500",
              padding: "8px 16px 8px 16px",
            },
          },
        },
      },
      // style height for pagination select tag
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.MuiInputBase-sizeSmall": {
              height: "24px",
              borderRadius: "4px",
            },
          },
        },
      },
      // style chevron on pagination select tag
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "rgba(255, 255, 255, 1)",
            fill: "rgba(255, 255, 255, 1)",
            "&.MuiSelect-icon, &.close-icon": {
              fontSize: "1.2rem",
            },
            "&.help-icon": {
              width: "18px",
              height: "18px",
            },
            "&.external-icon": {
              width: "18px",
              height: "18px",
            },                        
          },
        },
      },
      // style disabled but checked option in SortByDropdownMobile >> Manage columns
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.MuiButtonBase-root": {
              marginBottom: "0",
            },
            // override disabled & hovered styling
            "&.MuiIconButton-root:hover": {
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26)"
              }
            },
            "&.Mui-selected": {
              backgroundColor: "rgba(158, 158, 158, 0.16)",
              "&:hover": {
                backgroundColor: 'rgba(158, 158, 158, 0.1)',
              }
            },
            "&:hover": {
              backgroundColor: 'rgba(158, 158, 158, 0.1)',
            }
          },
        },
      },
      // center alert message
      MuiAlert: {
        styleOverrides: {
          message: {
            color: "#primary.main",
            display: "flex", 
            verticalAlign: "middle",
            padding: "10px"
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            padding: 8,
            '& .MuiSwitch-track': {
              backgroundColor: "#DDDDDD",
              borderRadius: 22 / 2,
              '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
              },
              '&::before': {
                left: 12,
              },
              '&::after': {
                right: 12,
              },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              width: 16,
              height: 16,
              margin: 2,
            },
          }
        }
      }
    },
    subscription: {
      subscribed: {
        color: "#009ECF",
        backgroundColor: "#E6F5FA"
      },
      default: {
        color: "#84909C",
        backgroundColor: "#DDE1E5"
      },
      oneTimePurchase: {
        color: "#43C06F",
        backgroundColor: "#ECF9F1"
      },
    }
  });

export default createDarkTheme; 